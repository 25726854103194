.container {
  min-height: 100vh;
  background-color: rgba(86, 211, 94, 0.14);
  color: black;
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: center;
  padding: 120px 30px 0 30px;

  .vacancyBlock {
    max-width: 900px;
    width: 100%;
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    .itemHeader {
      padding: 5px 0;
      font-size: 40px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .position {
        min-width: 100px;
        padding: 10px;
        font-weight: 600;
        margin: 5px;
      }
      .salary {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-weight: 600;
        padding: 10px;
        margin: 5px;
        .salaryValue {
          margin: 0 5px;
        }
      }
    }
    .description {
      font-size: 30px;
      padding: 10px;
      margin: 5px;
    }
    .title {
      font-size: 25px;
      font-weight: 600;
      padding: 0 10px;
      margin: 5px;
    }
    .requirements {
      font-size: 25px;
      padding: 0 10px;
      margin: 5px;
    }
    .condition {
      padding: 0 10px;
      margin: 5px;
    }
    .itemFooter {
      display: flex;
      font-size: 25px;
      justify-content: space-between;
      flex-wrap: wrap;
      .place {
        padding: 10px;
        margin: 5px;
        font-weight: 600;
      }
      .responseButton {
        padding: 10px;
        margin: 5px;
        font-weight: 600;
        border-radius: 5px;
        background-color: rgba(86, 211, 94, 0.84);
        box-shadow: 1px 2px 5px darkgray;
      }
      .responseButton:hover {
        background-color: black;
        color: azure;
      }
    }
  }
}