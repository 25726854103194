.container {
  min-height: 80px;
  width: 100%;
  z-index: 2;
  background-color: rgba(245, 245, 245, 0.85);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  box-shadow: 1px 2px 5px darkgray;
  .logoBlock {
    padding: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .title {
      font-size: 30px;
    }
  }
  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 20px 0 0;
    .contactItem {
      padding: 3px;
      .ref {
        text-decoration: none;
        color: black;
        .emailImage {
          width: 30px;
          height: 30px;
        }
      }
      .ref:hover {
        color: darkgray;
      }
    }
  }

  .logo {
    margin: 0 10px;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
