.App {
  //background-color: whitesmoke;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  font-size: 30px;
  padding: 80px 40px 40px 40px;
  .block {
    font-size: 35px;
    padding: 15px 0;
  }
  .item {
    padding: 7px 0;
  }
}