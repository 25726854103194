.contactsBlock {
  padding: 30px;

  .contactsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .contactItem {
    padding: 10px;
    .ref {
      color: black;
      text-decoration: none;
    }
    .ref:hover {
      color: darkgray;
    }
  }
}