.formField {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .textField {
    margin: 5px 0;
    padding: 0 7px;
    height: 40px;
    border-radius: 7px;
    font-size: 25px;
    border-color: rgba(0, 0, 0, 0);
    outline-color: cornflowerblue;
  }
  .textFieldError {
    margin: 5px 0;
    padding: 0 7px;
    height: 40px;
    border-radius: 7px;
    font-size: 25px;
    border-color: rgba(0, 0, 0, 0);
    outline-color: darkred;
  }
  .textError {
    color: darkred;
  }
}