.item {
  width: 100%;
  max-width: 900px;
  color: black;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 2px 5px darkgray;
  .itemHeader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .position {
      display: flex;
      justify-content: left;
      min-width: 100px;
      font-weight: 600;
      padding: 10px;
      margin: 5px;
    }
    .salary {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-weight: 600;
      padding: 10px;
      margin: 5px;
      .salaryValue {
        margin: 0 5px;
      }
    }
  }
  .description {
    padding: 10px;
    margin: 5px;
  }
  .title {
    font-weight: 600;
    padding: 0 10px;
    margin: 5px;
  }
  .requirements {
    padding: 0 10px;
    margin: 5px;
  }
  .itemFooter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .place {
      font-weight: 600;
      padding: 10px;
      margin: 5px;
    }
    .phone {
      padding: 5px;
      .phoneLink {
        color: black;
        text-decoration: none;
      }
      .phoneLink:hover {
        color: darkgray;
      }
    }
    .responseButton {
      padding: 10px;
      margin: 5px;
      font-weight: 600;
      text-align: center;
      background-color: rgba(86, 211, 94, 0.84);
      border-radius: 5px;
      box-shadow: 1px 2px 5px darkgray;
    }
    .responseButton:hover {
      background-color: black;
      color: azure;
    }
  }
}

.item:hover {
  background-color: whitesmoke;
}