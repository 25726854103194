.nav {
  //min-width: 100px;
  width: 60%;
  display: flex;
  justify-content: end;
  align-items: start;
  flex-wrap: wrap;
  .item {
    padding: 0px 5px;
    margin: 10px 5px;
    color: black;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    cursor: default;
  }
  .item:hover {
    color: darkgrey;
  }
}