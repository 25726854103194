.container {
  min-height: 100vh;
  padding: 120px 20px 20px 20px;
  background-color: rgba(86, 211, 94, 0.14);;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  .block {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    padding: 10px;
    margin: 10px;

    .sortBlock {
      max-width: 400px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .sortTitle {
        padding: 5px;
        font-size: 20px;
        color: black;
      }

      .sortInput {
        margin: 5px;
        padding: 3px;
        font-size: 15px;
        border-color: rgba(0, 0, 0, 0);
        box-shadow: 1px 2px 5px darkgray;
        border-radius: 2px;
      }
    }

    .vacancies {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item {
        max-width: 900px;
        color: rgba(0,0,0,0);
      }
    }
  }
}