.img {
  width: 40px;
  height: 40px;
  padding: 30px 10px 0;
  font-size: 5px;
}
.img:hover {
  path {
    stroke: darkgray;
  }
}
