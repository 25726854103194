.form {
  height: 330px;
  width: 80%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  .submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 7px;
    border-color: rgba(0, 0, 0, 0);
    background-color: rgba(86, 211, 94, 0.84);
    color: black;
    text-decoration: none;
    font-size: 25px;
    box-shadow: 1px 2px 5px darkgray;
  }
  .submitButton:hover {
    background-color: black;
    color: azure;
  }
  .submitButton:disabled {
    background-color: darkgrey;
  }
  .submitButton:disabled:hover {
    color: black;
  }
}